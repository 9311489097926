<template>
  <div>
   <div class="row top-row">
     <h2>Create a Devsite</h2>
     <div class='row offset-1'>
     <p>
     A Devsite is a sandbox area where you can work on your website and try new options, like new
     themes, plugins or new web applications before deploying to your live website. </p>
     <p>
     If you have website hosting with SWCP you can create a devsite for of any of those websites.<br>
     You can copy an existing site, set up a new WordPress installation, or work with an empty website.
     </p>
     <p>
     Add a contact email address if someone other than the requestor needs to be notified.
     </p>
     </div>
     <div class="col-12">

     <form  v-if="this.websites.length > 0" class="col-sm-12" v-on:submit.prevent="requestDevsite" ref="form">
        <div class="offset-sm-5 col-sm-3 row justify-content-md-center">
            <ErrorMessage :error="problems.domain" /><br>
        </div>
        <div class="form-group row">
            <label for='domain' class="col-sm-3 offset-2">Which Website?</label>
            <select id='domain' name="domain" class="selcss col-sm-5" v-model='userData.domain' as="select" >
               <option value="0" selected disabled>Select a Website</option>
               <option v-for="(ws) in websites"
                       v-bind:key="ws.virtname"
                       v-bind:value="ws.virtname"> {{ ws.virtname }} </option>
            </select>
        </div>
        <div class="form-group row">
            <label for='domain' class="col-sm-3 offset-2">Dev Site Type?</label>
            <select id='devsite_type' name='devsite_type' class='selcss col-6' 
                            v-model='userData.devsite_type'>
                    <option v-for="(dso) in ds_options"
                            v-bind:key="dso.name"
                            v-bind:value="dso.name"> {{ dso.name }} </option>
            </select>
        </div>
        <div class="form-group row">
            <label for='domain' class="col-sm-3 offset-2">Contact (email)</label>
            <input type='text' class='col-sm-6 form-control' v-model='userData.email_contact'>
        </div>
        <div class="offset-sm-3 row justify-content-md-center">
        </div>
        <div class="offset-sm-3 form-group row">
            <h5>If you have additional requirements, let us know what you need.</h5>
        </div>
        <div class="form-group row">
            <label for='notes' class="col-sm-3 offset-2">Special requests?</label>
            <div class="form-check form-check-inline col-sm-6">
                 <textarea class="col-9" rows="10" style="height:100%;" name="notes" v-model="userData.notes"></textarea>
             </div>
        </div>
        <div class="form-group row">
             <button class="offset-sm-3 col-md-4 btn btn-outline-primary"
                     type="submit"><i class="fa fa-lg fa-fw fa-share-from-square"></i>&nbsp;&nbsp; Setup</button>

        </div>

     </form>
     <div v-else>
       <div v-if="visibleBox">
           <p class="alert alert-primary offset-sm-2 col-sm-8">You don't seem to have any websites registered with us,
           or you already have devsites set up for them. You can refresh an old devsite with new content or add web
           hosting to an unused domain name.
           Would you like view your devsites or register or transfer a domain and set up webhosting?</p>
           <div class="row">
           <div class="col-3 offset-3">
                <button @click="$router.push('devsites')" class="btn btn-info">Look at your current Devsites</button>
           </div>
           <div class="col-3">
                <button @click="$router.push('domain-registration')" class="btn btn-info">Register a Domain</button>
           </div>
           </div>
       </div>
     </div>
     </div>

   </div>
  </div>

</template>

<script>

import axios from "axios";
import { useVuelidate } from '@vuelidate/core'
import { required, numeric, email, helpers } from '@vuelidate/validators';
import { not_negative, build_mail_message } from '@/helpers/utils.js';
import { DSR_template, DSR_items, DSR_subject_template, DSR_subject_items } 
        from '@/helpers/mail_templates/DSR.js';
import ErrorMessage from '@/components/ErrorMessage.vue';

export default {
      name: 'CreateDevsite',
      components: { ErrorMessage },
      emits: [ 'message' ],
      setup () {
         return { v$: useVuelidate() }
      },
      data () {
        return {
           visibleBox: 0,
           result: [ ],
           pending: [ ],
           websites: [ ],
           domsel: 'Select a website',
           ds_options: [ { name: 'Empty Website' },
                         { name: 'New WordPress Installation' },
                         { name: 'Copy original website' },
                       ],
           sendData: {  
               send_from: '',
               send_to: 'noc@swcp.com',
               footer: '',
               message: ''
           },    
           userData: {
               domain: '',
               devsite_type: 'Copy original website',
               email_contact: '',
               notes: ''
           },
           problems: {
               domain: ''
           },
           fields: [ 'domain', 'devsite_type', 'email_contact', 'notes' ],
         }
      },
      validations () {
        return {
           userData: {
               domain: { required: helpers.withMessage('Select a website', required) }
           }
        }
      },
      mounted() {
         this.setupApps();
         this.clearProbs();
      },
      watch: {
          current_user: function (newUser, oldUser) {
            console.log("watch called for " + newUser);
            this.clearForm();
            this.setupApps();
          }
      },
      methods: {
         clearProbs() {
            this.fields.forEach ((item) => {
               this.problems[item] = '';
            });                  
         },             
         responseMessage(title, body, type, alert, footer, menu) {
                var args = { title: title, body: body,
                             type: type, alert: alert,
                             footer: footer, menu: menu };
                this.$store.commit('setStatus', args);
                this.$emit('message');
                return;
         },
         setupApps() {
            this.getVirtList();
            this.userData.email = this.customer_email;
         },
         clearForm() {
            this.fields.forEach ((item) => {
               this.userData[item] = '';
            });                  
            this.userData.email_contact = "";
            this.userData.notes = "";
         },
        send_message( msg, subject ) {
            var url = "/api/sendmessage";
            var success = "Your web hosting request has been submitted. <br>If you have any questions you can reach us at 505-232-7992 or help@swcp.com.";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.sendData.send_from = this.customer_email;
            this.sendData.message = msg;
            this.sendData.subject = subject;
            this.sendData.footer = this.footer_tag;
            axios.post(url, this.sendData, config )
            .then (
                   response => {
                      this.response_status = response.data.status;
                      if (this.response_status) {
                          this.responseMessage('Domain Registration Submitted', success, 'thankyou', 'info', '', [ ] );
                      } else {
                          this.responseMessage('Error sending registration', response.data.message, 'modal', 'serious', '', []);
                      }
                   })
            .catch(error => {
                      if (error.response) {
                         if (error.response.data.status && error.response.data.status == "expired_token") {
                             this.$store.dispatch('expired');
                         }
                      }
                      this.response_status = error;
                      this.responseMessage('Problems with Request', error, 'modal', 'serious', '', []);
                   })

         },

         getVirtList () {
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            this.account = this.current_user;
            axios.get("/api/compressed-nondev-websites/" + this.account , config )
            .then (
                    response => {
                          this.websites = response.data.data;
                          this.visibleBox = 1;
                    })
            .catch(error => {
                    var notes = "";
                    if (error.response) {
                       if (error.response.data.message) {
                           notes = error.response.data.message;
                       }
                       if (error.response.data.status && error.response.data.status == "expired_token") {
                           this.$store.dispatch('expired');
                       }
                    }
                    this.websites = [ ];
                    })
         },
         async requestDevsite () {
// TODO: Finish this!!
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            const result = await this.v$.$validate();
            var subject, message, property, email;
            if (! result) {
                this.clearProbs(); // Set all problems to ''
                this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      this.problems[property] = message;
                });
            } else {
                this.account = this.current_user;
                this.clearProbs(); // Set all problems to ''
               
                DSR_items.domain = this.userData.domain;
                DSR_items.devsite_type = this.userData.devsite_type;
                DSR_items.logname = this.current_user;
                DSR_items.email_contact = this.userData.email_contact;
                DSR_items.notes = this.userData.notes;
                DSR_subject_items.logname = this.current_user;
                DSR_subject_items.domain = this.userData.domain;

                message = build_mail_message (DSR_template, DSR_items);
                subject = build_mail_message (DSR_subject_template, DSR_subject_items);
                this.send_message (message, subject);
             }
          }
      },
      computed: {
          footer_tag () {
              if (this.operator === this.current_user) {
                 return "Message sent by " + this.operator;
              } else {
                 return "Message sent by " + this.operator + " for " + this.current_user;
              }       
          },              
          operator ()       { return this.$store.state.operator; },
          current_user ()   { return this.$store.state.current_user; },
          jwt ()            { return this.$store.state.jwt; },
          customer_email () { return this.$store.state.reply_to; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
.selcss {
   padding: 10px;
   border: 1px solid dodgerblue;
   border-radius: 5px;
}
.table td, .table th {
    padding: .5rem .75rem;
}
.table th {
    color: #333333;
}
.top-row h1, h2, h3, h4, h5, h6 {
    margin-top: .5em;
}

</style>
