export const DSR_template = `
   Please set up a devsite 

   Logname: {{logname}} 
   Domain:  {{domain}}
   Type: {{devsite_type}}
   Contact: {{email_contact}}
   Additional Notes: 
--------------------
{{{notes}}}

   Please send a message to the customer when this is set up.
`;

export const DSR_items = {
   logname: '',
   domain: '',
   devsite_type: '',
   email_contact: '',
   notes: ''
};

export const DSR_subject_template = `Subject: {{logname}} needs a devsite for {{domain}}`;

export const DSR_subject_items = { 
    logname: '',
    domain: ''
};
